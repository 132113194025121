import React from "react";
import { Switch } from "@mui/material";

function Component(props) {
    return (
        <Switch
            sx={{
                transform: 'rotate(180deg)'
            }}
            checked={!props.DarkMode}
            onChange={(e) => {
                props.setDarkMode(!e.target.checked);
                localStorage.setItem('darkmode', !e.target.checked);
            }}
            color='warning'
            inputProps={{ 'aria-label': 'controlled' }}
        />
    )
}
export default Component