async function getUserLocation() {
    try {
        const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const { latitude, longitude } = position.coords;
        return { lat: latitude, lng: longitude };
    } catch (error) {
        return null;
    }
}

export default getUserLocation