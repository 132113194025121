function CalcLocationDistance(loc1, loc2) {
    let distance = 0
    if (!!loc1 && !!loc2)
        distance=dist(loc1, loc2);
        // distance = Math.acos(Math.sin(loc1.lat) * Math.sin(loc2.lat) + Math.cos(loc1.lat) * Math.cos(loc2.lat) * Math.cos(loc2.lng - loc1.lng)) * 6371;
    let unit =' km';
    if(distance<2){
        distance*=1000
        unit=' m'
    }
    return distance.toFixed(0) + unit;
}

export default CalcLocationDistance



function dist(loc1, loc2) {
    const r = 6371; // km
    const p = Math.PI / 180;
  
    const a = 0.5 - Math.cos((loc2.lat - loc1.lat) * p) / 2
                  + Math.cos(loc1.lat * p) * Math.cos(loc2.lat * p) *
                    (1 - Math.cos((loc2.lng - loc1.lng) * p)) / 2;
  
    return 2 * r * Math.asin(Math.sqrt(a));
  }