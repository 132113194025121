import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DarkModeToggle from './DarkModeToggle.js'
import SignOut from './SignOut.js'
import { Badge } from '@mui/material';


export default function PrimarySearchAppBar(props) {
    const [MenuAnchor, setMenuAnchor] = React.useState(null);
    return (
        <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                {props.User.uid ? (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            mr: 2,
                            display: {
                                xs: 'block',
                                md: 'none',
                            }
                        }}
                        onClick={props.SwipeableToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                ) : ''}
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                >
                    ArgentoGusto
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {(props.User.uid && props.showProductsIcon) ? (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{
                            mr: 2,
                            display: {
                                xs: 'block',
                                md: 'none',
                            }
                        }}
                        onClick={props.ProductCount ? props.ProductsSwipeableToggle: props.CreateProduct}
                    >
                        <Badge badgeContent={props.ProductCount || <AddCircleIcon />}>
                            <i className='fa-fw fas fa-box-heart' />
                        </Badge>
                    </IconButton>
                ) : ''}
                {props.User.uid ? (
                    <Box >
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            onClick={e => setMenuAnchor(e.currentTarget)}
                            color="inherit"
                        >
                            {props.User.pic ? (
                                <Box
                                    component="img"
                                    src={props.User.pic}
                                    sx={{
                                        width: '2rem',
                                        height: '2rem',
                                        borderRadius: '50%',
                                    }}
                                />
                            ) : (
                                <AccountCircle />
                            )}
                        </IconButton>
                    </Box>
                ) : (
                    <DarkModeToggle
                        DarkMode={props.DarkMode}
                        setDarkMode={props.setDarkMode}
                    />
                )}
            </Toolbar>
            <Menu
                anchorEl={MenuAnchor}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!MenuAnchor}
                onClose={() => setMenuAnchor(null)}
            >
                <MenuItem onClick={() => setMenuAnchor(null)}>
                    <DarkModeToggle
                        DarkMode={props.DarkMode}
                        setDarkMode={props.setDarkMode}
                    />
                </MenuItem>
                <MenuItem
                    onClick={() => setMenuAnchor(null)}
                    sx={{ justifyContent: 'center' }}
                ><SignOut /></MenuItem>
            </Menu>
        </AppBar>
    );
}