const TextStyles = {
    h1: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBlockStart: '0.67em',
        marginBlockEnd: '0.67em',
        htmlInput: {
            style: {
                fontSize: '2em',
                fontWeight: 'bold',
            }
        }
    },
    h2: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        htmlInput: {
            style: {
                fontSize: '1.5em',
                fontWeight: 'bold',
            }
        }
    },
    h3: {
        fontSize: '1.17em',
        fontWeight: 'bold',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        htmlInput: {
            style: {
                fontSize: '1.17em',
                fontWeight: 'bold',
            }
        }
    },
    h4: {
        fontWeight: 'bold',
        marginBlockStart: '1.33em',
        marginBlockEnd: '1.33em',
        htmlInput: {
            style: {
                fontWeight: 'bold',
            }
        }
    },
    h5: {
        fontSize: '.83em',
        fontWeight: 'bold',
        marginBlockStart: '1.67em',
        marginBlockEnd: '1.67em',
        htmlInput: {
            style: {
                fontSize: '.83em',
                fontWeight: 'bold',
            }
        }
    },
    h6: {
        fontSize: '.67em',
        fontWeight: 'bold',
        marginBlockStart: '2.33em',
        marginBlockEnd: '2.33em',
        htmlInput: {
            style: {
                fontSize: '.67em',
                fontWeight: 'bold',
            }
        }
    },
}
export default TextStyles