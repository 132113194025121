import React from "react";
import ReactDOM from 'react-dom/client';
import Auth from './components/AuthComp.js'
import reportWebVitals from './reportWebVitals';
import { APIProvider } from '@vis.gl/react-google-maps';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <APIProvider apiKey={'AIzaSyDs9JufYPrtxm-cIaEgoBVU3tgUCRTZXsI'}>
      <Auth />
    </APIProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
