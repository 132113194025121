import React from "react";
import Button from '@mui/material/Button';
import { auth } from "./firebase.js";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import GoogleIcon from '@mui/icons-material/Google';


function SignIn() {
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        auth.languageCode = 'es';
        signInWithPopup(auth, provider).catch(console.log);
    }
    return (
        <Button
            variant="contained"
            startIcon={<GoogleIcon />}
            onClick={signInWithGoogle}
            sx={{
                m: 'auto',
            }}
            id="sign-in-button"
        >Ingresa con Google</Button>
    );
}

export default SignIn;
