import React, { useEffect, useState, useRef } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import getUserLocation from './GetUserLocation.js'
import CalcLocationDistance from './CalcLocationDistance.js'
import LocationPick from './LocationPick.js'
import PicturePicker from './PicturePicker.js'
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';

function Component(props) {
    const [LocationPicking, setLocationPicking] = useState(false);
    const [LocationPickingBase, setLocationPickingBase] = useState(null);
    const [LocationPickingIndex, setLocationPickingIndex] = useState(null);
    const [PlaceName, setPlaceName] = useState(props.ActivePlace.name);
    const [PlaceNameModified, setPlaceNameModified] = useState({});
    const [PlaceCategory, setPlaceCategory] = useState(props.ActivePlace.category);
    const [PlaceCategoryModified, setPlaceCategoryModified] = useState({});
    const [PlaceDesc, setPlaceDesc] = useState(props.ActivePlace.description);
    const [PlacePics, setPlacePics] = useState([]);

    // const PlacePics-Ref = useRef(props.ActivePlace.pics || []);

    const [PlaceDescModified, setPlaceDescModified] = useState({});
    const [Locations, setLocations] = useState(JSON.parse(JSON.stringify(props.ActivePlace.locations || [])));
    const [PlaceModified, setPlaceModified] = useState(false);
    const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(null);
    const [RmConfirmationDialogOpen, setRmConfirmationDialogOpen] = useState(null);
    const [ConfirmationDialogText, setConfirmationDialogText] = useState(null);
    const [UserLocation, setUserLocation] = useState({ lat: -34.698900, lng: -64.759700, address: '' });
    const loading = useRef(false);

    useEffect(() => {
        loading.current = true;
        setPlaceName(props.ActivePlace.name || '');
        setPlaceCategory(props.ActivePlace.category || '');
        setPlaceDesc(props.ActivePlace.description || '');
        setLocations(JSON.parse(JSON.stringify(props.ActivePlace.locations || [])));
        setPlacePics([...props.ActivePlace.pics || []]);
    }, [props.ActivePlace.plid, props.ActivePlace])

    useEffect(() => {
        if (PlaceName !== (props.ActivePlace.name || ''))
            setPlaceNameModified({
                focused: true,
                color: "success"
            })
        else
            setPlaceNameModified({})
        setPlaceModifiedFn();
    }, [PlaceName, props.ActivePlace.name])

    useEffect(() => {
        if (PlaceDesc !== (props.ActivePlace.description || ''))
            setPlaceDescModified({
                focused: true,
                color: "success"
            })
        else
            setPlaceDescModified({})
        setPlaceModifiedFn();
    }, [PlaceDesc, props.ActivePlace.description])

    useEffect(() => {
        if (PlaceCategory !== (props.ActivePlace.category || ''))
            setPlaceCategoryModified({
                color: "success"
            })
        else
            setPlaceCategoryModified({})
        setPlaceModifiedFn();
    }, [PlaceCategory, props.ActivePlace.category])

    useEffect(() => {
        setPlaceModifiedFn();
    }, [PlacePics, props.ActivePlace.pics])

    const setLocation = (pos, loc) => {
        let Locs = Locations || [];
        if (loc)
            Locs[pos] = loc;
        else
            Locs.splice(pos, 1);
        setLocations(Locs);
        setLocationPicking(false);
        setPlaceModifiedFn();
    }

    const setPlaceModifiedFn = () => {
        let mod = (
            PlaceName !== (props.ActivePlace.name || '')
            || PlaceDesc !== (props.ActivePlace.description || '')
            || PlaceCategory !== (props.ActivePlace.category || '')
            || JSON.stringify(Locations) !== JSON.stringify(props.ActivePlace.locations || [])
            || JSON.stringify(PlacePics) !== JSON.stringify(props.ActivePlace.pics || [])
        )
        if (!mod && loading.current)
            loading.current = false
        setPlaceModified(
            mod && !loading.current
        )
    }

    const uploadPlace = () => {
        set(ref(rtdb, "public/Places/" + props.ActivePlace.plid),
            {
                name: PlaceName || '',
                description: PlaceDesc || '',
                category: PlaceCategory || '',
                locations: Locations || [],
                pics: PlacePics || [],
            }
        );
    }

    const deleteProduct = () => {
        for (let prid in props.ActivePlace.products)
            set(ref(rtdb, "public/Products/" + prid), null);
        set(ref(rtdb, "public/Places/" + props.ActivePlace.plid), null);
        setRmConfirmationDialogOpen(null);
        props.ClosePlace();
    }

    useEffect(() => {
        getUserLocation().then(loc => {
            if (loc)
                setUserLocation({ ...loc, address: '' });
        });
    }, [])

    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                flex: {
                    xs: '100% 0 1',
                    md: '50vw 0 0',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: '95%',
                overflow: 'auto',
                m: 2,
                mb: 'auto'
            }}
            key={'edit' + props.ActivePlace.plid}
        >
            <TextField
                label='Nombre'
                slotProps={TextStyles.h2}
                {...PlaceNameModified}
                disabled={LocationPicking || !props.Admin}
                sx={{
                    flexGrow: 1
                }}
                value={PlaceName || ''}
                onChange={e => setPlaceName(e.target.value)}
            />
            <FormControl>
                <InputLabel>Categoría</InputLabel>
                <Select
                    {...PlaceCategoryModified}
                    disabled={LocationPicking || !props.Admin}
                    value={PlaceCategory || ''}
                    onChange={e => setPlaceCategory(e.target.value)}
                    input={<OutlinedInput label="Categoría" />}
                >
                    {Object.keys(props.categories).map(category => {
                        if (category.toLowerCase() !== 'default')
                            return (
                                <MenuItem
                                    key={'catSelector' + category}
                                    value={category}
                                >
                                    {category}
                                </MenuItem>
                            )
                        else return '';
                    })}
                </Select>
            </FormControl>
            <TextField
                label='Descripción'
                {...PlaceDescModified}
                disabled={LocationPicking || !props.Admin}
                sx={{
                    flexGrow: 1
                }}
                multiline
                rows={4}
                value={PlaceDesc || ''}
                onChange={e => setPlaceDesc(e.target.value)}
            />
            <Accordion
                onChange={(e, expanded) => {
                    if (!expanded)
                        setLocationPicking(false);
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    {props.ActivePlace.locations ? (props.ActivePlace.locations.length === 1 ? (props.ActivePlace.locations[0].address.split(',')[0]) : (props.ActivePlace.locations.length + ' direcciones')) : '0 direcciones'}
                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <List>
                            {(Locations || []).map((location, i) => {
                                return (
                                    <ListItem
                                        disablePadding
                                        key={'location' + props.ActivePlace.plid + i + JSON.stringify(location)}
                                    >
                                        <ListItemButton>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    width: 1,
                                                }}
                                            >
                                                <PlaceIcon />
                                                <ListItemText
                                                    primary={location.address + ' (' + CalcLocationDistance(location, UserLocation) + ')'}
                                                />
                                                {props.Admin ? (
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="Quitar"
                                                        title="Quitar"
                                                        onClick={e => {
                                                            setConfirmationDialogText(location.address || '')
                                                            setConfirmationDialogOpen(i);
                                                        }}
                                                    >
                                                        <LocationOffIcon />
                                                    </IconButton>
                                                ) : ''}
                                                {props.Admin ? (
                                                    <IconButton
                                                        edge="end"
                                                        title="Modificar"
                                                        aria-label="edit"
                                                        onClick={e => {
                                                            setLocationPicking(true);
                                                            setLocationPickingBase(location);
                                                            setLocationPickingIndex(i);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                ) : ''}
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        {props.Admin ? (
                            <Button
                                variant="outlined"
                                endIcon={<AddLocationIcon />}
                                sx={{
                                    mt: 1,
                                    mx: 'auto',
                                }}
                                onClick={e => {
                                    setLocationPicking(true);
                                    setLocationPickingBase(Locations[0] || UserLocation);
                                    setLocationPickingIndex(Locations.length || 0);
                                }}
                            >
                                Agregar
                            </Button>
                        ) : ''}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <LocationPick
                LocationBase={LocationPickingBase || {}}
                LocationPickingIndex={LocationPickingIndex || 0}
                Show={setLocationPicking}
                setLocation={setLocation}
                Open={LocationPicking}
            />
            <PicturePicker
                DarkMode={props.DarkMode}
                Pictures={props.Pictures}
                UsedPics={PlacePics}
                setUsedPics={setPlacePics}
                Admin={props.Admin}
            />
            {(props.Admin && props.ActivePlace.name !== undefined) ? (
                <Button
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    disabled={false}
                    color="error"
                    onClick={e => setRmConfirmationDialogOpen(true)}
                    sx={{
                        mr: 'auto',
                    }}
                >
                    Borrar
                </Button>
            ) : ''}
            {(PlaceModified && props.Admin) ? (<Button
                variant="contained"
                endIcon={<CloudUploadIcon />}
                disabled={LocationPicking}
                sx={{
                    ml: 'auto',
                    position: 'sticky',
                    bottom: 0,
                    mt: props.ActivePlace.name !== undefined ? -6.5 : 0,
                }}
                onClick={e => uploadPlace()}
            >
                Grabar
            </Button>) : ''}
            <Dialog
                onClose={() => setConfirmationDialogOpen(null)}
                open={ConfirmationDialogOpen !== null}
            >
                <DialogTitle id="simple-dialog-title">Confirma la eliminación de</DialogTitle>
                <DialogContent>
                    {ConfirmationDialogText}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setConfirmationDialogOpen(null)}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            setLocation(ConfirmationDialogOpen, undefined);
                            setConfirmationDialogOpen(null)
                        }}
                        color="primary"
                    >
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={() => setRmConfirmationDialogOpen(null)}
                open={RmConfirmationDialogOpen !== null}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    Confirma la eliminación del local<br />
                    <big>{props.ActivePlace.name || 'Sin Nombre'}</big><br />
                    {Object.keys(props.ActivePlace.products || {}).length ? (
                        <Box>
                            y sus {Object.keys(props.ActivePlace.products || {}).length} productos:
                            <ul
                                style={{
                                    textAlign: 'start',
                                    maxHeight: '30vh',
                                    overflowY: 'auto',
                                }}
                            >
                                {Object.keys(props.ActivePlace.products || {}).map(prid => {
                                    return (
                                        <li
                                            key={'del' + prid}
                                        >
                                            {props.ActivePlace.products[prid].name || 'Sin Nombre'}
                                        </li>
                                    )
                                })}
                            </ul>
                        </Box>
                    ) : ''}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setRmConfirmationDialogOpen(null)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => {
                            deleteProduct();
                            setRmConfirmationDialogOpen(null);
                        }}
                    >
                        Borrar
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default Component