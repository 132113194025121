import React from "react";
import { auth } from "./firebase.js";
import { signOut } from "firebase/auth";
import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton } from "@mui/material";


function SignIn() {
    return (
        <IconButton
            onClick={() => signOut(auth).catch(console.log)}
            aria-label="Logout"
            title="Salir"
        >
            <LogoutIcon />
        </IconButton>
    );
}

export default SignIn;
